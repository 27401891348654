<template>
  <div class="connect">
    <circle1 v-if="loading"></circle1>
    <i class="el-icon-error" v-if="errMsg"></i>
    <p v-if="errMsg">{{errMsg}}</p>
  </div>
</template>

<script>
import { parseQueryString } from '@/common/utils'
import { Circle as Circle1 } from 'vue-loading-spinner'
import { LoginByDownMD5 } from '@/api/login'
import login from './login-mixin.js'
import { setSessionConnectKey, setOutUrl } from '@/common/cache'
import { QueryObjectIDByDeviceID, QueryObjectIDByVehicleName } from '@/api/common'
import { mapMutations } from 'vuex'
export default {
  name: 'connect',
  beforeCreate() {
    document.querySelector(':root').style.minWidth = '400px'
  },
  created() {
    this.go()
  },
  beforeRouteUpdate() {
    this.go()
  },
  data() {
    return {
      loading: true,
      errMsg: ''
    }
  },
  methods: {
    go() {
      var searchString = ''
      var startIndex = window.location.href.indexOf('?')
      if (startIndex != -1) {
        var endIndex = window.location.href.indexOf('#')
        if (endIndex < startIndex) {
          searchString = window.location.href.substring(startIndex)
        } else {
          searchString = window.location.href.substring(startIndex, endIndex)
        }
      }
      var qs = parseQueryString(searchString)
      var key = qs.key || ''
      setSessionConnectKey(key)
      LoginByDownMD5(key)
        .then(ret => {

          // 设置左侧目录
          const left = qs.fun === '1'
          this.setMonitorLeft(left)
          let tags = qs.nocache&&qs.nocache==2? true:false
          let result =  this.handleLogin(ret,tags)
          if (result.data === '/online/monitor'
            || (result.data === '/wono/manage' && qs.fun == '2')) {

            let success = d => {
              let oid = d.data.data
              if (oid.length > 0) {
                if (qs.fun == '5') {
                  // 自动登录
                  setSessionConnectKey()
                  qs.out_url && setOutUrl(decodeURIComponent(qs.out_url))
                  // 在线监控
                  this.$router.push(result.data + `?action=addTaskList&objectid=${oid.join(',')}`)
                } else if (qs.fun == '3') {
                  this.$router.push('/online/track' + `?id=${oid[0]}`) // 轨迹回放
                } else {
                  this.$router.push('/online/monitor' + `?action=addTaskList&objectid=${oid.join(',')}`) // 在线监控
                }
              } else {
                this.errMsg = `${this.$t('login.notFoundID')}:${decodeURIComponent(qs.ids)}`
              }
            }

            // 成功
            if (qs.fun == '4') {
              // 直接进入报表
              this.$router.push('/report/one')
            } else if (qs.ids && qs.type == '1') {
              // 根据车牌号查车
              QueryObjectIDByVehicleName(decodeURIComponent(qs.ids)).then(success)
            } else if (qs.ids) {
              // 根据设备ID查车
              QueryObjectIDByDeviceID(qs.ids).then(success)
            } else {
              if (qs.fun == '5') {
                setSessionConnectKey()
                qs.out_url && setOutUrl(decodeURIComponent(qs.out_url))
              }
              this.$router.push(result.data)
            }

          } else if (result.data) {
            this.$router.push(result.data)
          } else {
            this.errMsg = result.errMsg
          }

        })
        .catch(err => {
          this.errMsg = err.message
        })
        .finally(() => {
          this.loading = false
        })
    },
    ...mapMutations({
      'setMonitorLeft': 'SET_MONITOR_LEFT'
    })
  },
  components: {
    Circle1
  },
  mixins: [
    login
  ]
}
</script>

<style lang="scss" scoped>
.connect {
  height: calc(100vh - 17px);
  width: calc(100vw - 17px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  i {
    margin-bottom: 12px;
    opacity: .56;
    font-size: 2em;
  }
}
</style>
