import { setUserRole, setLastLoginInfo, getLastLoginInfo, setUserTreeSetting, getUserTreeSetting, getVisitedViewInfo } from '@/common/cache'
import { setToken,setSessionToken, removeToken } from '@/common/auth'
import { BASE_URL } from '@/api/config'
import { mapMutations } from 'vuex'

let mixin = {
  methods: {
    ...mapMutations({
      setUserName: 'SET_USER_NAME',
      setUserId: 'SET_USER_ID',
      setLoginInfo: 'SET_LOGIN_INFO',
      setCurrentHoldId: 'SET_CURRENT_HOLD_ID',
      setCurrentHoldName: 'SET_CURRENT_HOLD_NAME',
      setAllViews: 'SET_ALL_VIEWS',
      setCurrentMap: 'SET_CURRENT_MAP'
    }),
    // {
    //   errCode: 0,
    //   errMsg: 'ok',
    //   data: '/online/customer'
    // }
    handleLogin(ret,notCache) {

      // 登录失败, 10是有效期不足
      if (ret.data.errCode && ret.data.errCode != 10) {
        removeToken()
        setUserRole()
        let result = {
          errCode: ret.data.errCode,
          errMsg: ret.data.errMsg,
          data: null
        }
        return result
      }

      // 登录成功
      // 1.清除用户树勾选列表
      let state = getUserTreeSetting({})
      Object.assign(state, {
        checkList: [],
        containChild: true
      })
      setUserTreeSetting(state)

      // 2.设置凭证
      if(notCache){
        setToken(ret.data.loginKey)
      }else{
        removeToken()
        setSessionToken(ret.data.loginKey)
      }
      // 3.设置用户头像
      ret.data.info.PlatformLogo = `${BASE_URL}/assets/Logo?holdid=${ret.data.info.HoldID}`// 增加图片地址
      this.setUserName(ret.data.info.LoginName) // 登录用户名
      this.setUserId(ret.data.info.UserID) // 登录用户ID
      this.setLoginInfo(ret.data.info) // 保存登录全部信息
      this.setCurrentHoldName(ret.data.info.HoldName) // 保存当前选择用户名
      this.setCurrentHoldId(ret.data.info.HoldID) // 保存当前选择用户ID

      // 4.保存最后登录信息(标题，图标)
      const lastLoginInfo = getLastLoginInfo()
      lastLoginInfo.PlatformTitle = ret.data.info.PlatformTitle
      lastLoginInfo.HoldID = ret.data.info.HoldID
      lastLoginInfo.PlatformLogo = ret.data.info.PlatformLogo
      setLastLoginInfo(lastLoginInfo)

      // 设置用户权限
      setUserRole({
        UserType: ret.data.info.UserType,
        UserFunList: ret.data.info.allFuns
      })

      // 加载缓存的标签页
      const views = getVisitedViewInfo()
      if (views && views.userId === ret.data.info.UserID && views.v === window.compileVersion) {
        this.setAllViews(views)
      } else {
        this.setAllViews({ visitedViews: [], cachedViews: []})
      }

      if (ret.data.info.UserType === 961 || ret.data.info.UserType === 962 || ret.data.info.UserType === 963) {
        let result = {
          errCode: ret.data.errCode,
          errMsg: ret.data.errMsg,
          data: '/wono/manage'
        }
        return result
      } else {
        let result = {
          errCode: ret.data.errCode,
          errMsg: ret.data.errMsg,
          data: '/online/monitor'
        }
        return result
      }
    }
  }
}

export default mixin
